import React from 'react'

import ContactSection from '../components/ContactSection'
import FooterSection from '../components/FooterSection'
import Navbar from '../components/Navbar'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'
import {Link} from "gatsby"
import Layout from '../components/Layout'


const careers = () => {
  return (
    <>
      <Layout>
        <SEO title="Careers | Pandigita" />
        <Navbar bgColor="#EFF0F1" />
        {/* Start Career Page Section */}
        <PageBanner pageTitle="Careers" pageDesc="Are you an above average developer who finds joy in high quality code, bright colleagues and sleek web and mobile applications? Join our small remote team at Pandigita."/>
        <section className="career-page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="careers-wrapper">
                  <ul>
                    <li>
                      <div className="career-item">
                        <div className="job-summary">
                          <div className="job-title">
                            <h4>React / Node.js Fullstack Developer (Remote)</h4>
                          </div>
                          <p>Looking for a talented frontend developer, who knows that rock-solid frontend logic and React coding best practices are just as important as a beautiful UI.</p>
                        </div>
                        <div className="apply-button">
                          <Link to="/react-nodejs-fullstack" className="apply-btn">View Details</Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="career-item">
                        <div className="job-summary">
                          <div className="job-title">
                            <h4>React/Redux Frontend Developer (Remote) <span>Frontend</span></h4>
                          </div>
                          <p>Looking for a talented frontend developer, who knows that rock-solid frontend logic and React coding best practices are just as important as a beautiful UI.</p>
                        </div>
                        <div className="apply-button">
                          <Link to="/reactjs-job" className="apply-btn">View Details</Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="career-item">
                        <div className="job-summary">
                          <div className="job-title">
                            <h4>Node.js Backend Developer (Remote)<span>Backend</span></h4>
                          </div>
                          <p>You plan ahead to build high quality backends and endpoints? AND you communicate well? We've been waiting for you.</p>
                        </div>
                        <div className="apply-button">
                          <Link to="/nodejs-job" className="apply-btn">View Details</Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="career-item">
                        <div className="job-summary">
                          <div className="job-title">
                            <h4>Product Owner / Project Manager<span>All-Arounder</span></h4>
                          </div>
                          <p>Have you product managed/project managed multiple web projects before, have foresight, great English, are detail oriented and help developers be productive?</p>
                        </div>
                        <div className="apply-button">
                          <a href="mailto:contact@pandigita.com" className="apply-btn">Email Us</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Career Page Section */}
        <ContactSection />
        <FooterSection/>
      </Layout>
    </>
  )
}

export default careers
