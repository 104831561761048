import React from 'react'

const PageBanner = ({pageTitle, pageDesc, className}) => {
  return (
    <>
      <section className={["page-banner", className || ""].join(" ")}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="page-title">
                <div className="small-underline"></div>
                <h1>{pageTitle}</h1>
                <p>{pageDesc}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PageBanner
